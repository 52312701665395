import Link from 'next/link'
import React from 'react'
import styles from './Footer.module.scss'
import Image from 'next/image'
import SocialLinks from '../SocialLinks/SocialLinks'
// import SocialLinks from './common/SocialLinks/SocialLinks'

const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
    <div className={styles.footerContainer}>
      {/* Footer Links */}
      <div className={styles.footerLinks}>
        {/* Logo Section */}
        <div className={styles.footerGroup}>
          <div className={styles.logo}>
            <Image src="logo.svg" width={30} height={30} alt="Socommerz Logo" />
            <span>SOCOMMERZ</span>
          </div>
          <div className={styles.socialLinks}>
            <SocialLinks />
          </div>
        </div>

        {/* Company Links */}
        <div className={styles.footerGroup}>
          <h3>Company</h3>
          <Link href="/about-us">About Us</Link>
          <Link href="/product-stack">Product</Link>
          <Link href="/b2b-stack">B2B Stack</Link>
          <Link href="/our-associations">Our Associations</Link>
        </div>

    {/* Price Engine */}
    <div className={styles.footerGroup}>
          <h3>Price Engine</h3>
          <Link href="/eTail-data-lake">eTail Data Lake</Link>
          <Link href="/headless-price-engine-ai">Headless Price Engine</Link>
          <Link href="/trend-analyzer">Trend Analyzer</Link>
        </div>
        {/* Solutions */}
        <div className={styles.footerGroup}>
          <h3>Solutions</h3>
          <Link href="/b2b-stack">B2B Stack</Link>
          <Link href="/b2c-d2c-stack">B2C & D2C Stack</Link>
          <Link href="/social-commerce">Social Commerce Stack</Link>
          <Link href="/generative-ai">Generative AI Wrappers</Link>
          <Link href="/product-stack">Headless Solutions</Link>
          <Link href="/commerce-apis">Commerce APIs</Link>
          <Link href="/oms-solutions">Order Management Solutions</Link>
        </div>

    

        {/* Head Office */}
        <div className={styles.footerGroup}>
          <h3>Head Office</h3>
          <p>
            Cluster-F Jumeirah
            Lake Towers, Dubai, UAE
          </p>
        </div>
      </div>

      {/* Copyright Section */}
      <div className={styles.copyRight}>
        <span>© 2025 Socommerz Technologies Private Limited. All rights reserved.</span>
      </div>
    </div>
  </footer>
  )
}

export default Footer
